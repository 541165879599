var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.classes,
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _c(
        "markets-ui-settings",
        {
          ref: "glaUiSettings",
          attrs: {
            slot: "settings",
            title: _vm.initedLabels.settingTitle,
            skin: _vm.watchedSettings.skin,
            showBack: !!_vm.settingsPopoverNavSelected,
            "triggered-by": _vm.settingsPopoverTriggeredId,
            visible: _vm.settingsPopoverVisible
          },
          on: { hide: _vm.hideSettingsPopover, back: _vm.changeSettingsNav },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.settingsPopoverNavSelected
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  size: "small",
                  variation: "selection",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNavSelectedList
                },
                on: { change: _vm.changeSettingsNavSelected },
                slot: "content"
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.errorMessage
        ? _c(
            "div",
            {
              class: _vm.namespace("body"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c(
                "div",
                { class: _vm.namespace("navigation") },
                [
                  _vm.showWebLayout
                    ? _c("markets-ui-menus", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          "data-model": _vm.viewMenuDataModel,
                          "collapse-mode": "collapse",
                          label: _vm.initedLabels.view
                        },
                        on: { change: _vm.changeViewType }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showWebLayout
                    ? _c("markets-ui-menus", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          "data-model": _vm.exchangeMenuDataModel,
                          "collapse-mode":
                            _vm.watchedSettings.viewType === _vm.VIEW_TYPES.TAB
                              ? "collapse"
                              : "auto"
                        },
                        on: { change: _vm.changeSymbol }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.watchedSettings.viewType === _vm.VIEW_TYPES.TAB
                    ? _c("markets-ui-menus", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          dataModel: _vm.menuDataModel
                        },
                        on: { change: _vm.changeType }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.namespace("grid-container"),
                    _vm.watchedSettings.viewType === _vm.VIEW_TYPES.TAB ||
                    _vm.showWebLayout
                      ? ""
                      : _vm.namespace("grid-container__no-menu")
                  ]
                },
                [
                  _vm.watchedSettings.viewType === _vm.VIEW_TYPES.STACK &&
                  !_vm.showLoading
                    ? _c(
                        "section",
                        { class: _vm.stackClasses },
                        _vm._l(_vm.stackDataModel, function(stack, index) {
                          return _c(
                            "div",
                            { key: index, class: _vm.namespace("stack") },
                            [
                              _c("StackQuote", {
                                attrs: {
                                  skin: _vm.watchedSettings.skin,
                                  labels: _vm.initedLabels,
                                  noData: stack.noData,
                                  dataModel: stack.quoteData,
                                  symbolClickable:
                                    _vm.watchedSettings.symbolClickable,
                                  showStackTitle:
                                    _vm.watchedSettings.showStackTitle
                                },
                                on: { "symbol-click": _vm.symbolClick }
                              }),
                              _vm._v(" "),
                              !!stack.gridData.length
                                ? _c("markets-ui-grid", {
                                    attrs: {
                                      skin: _vm.skin,
                                      option: {
                                        autoHeight:
                                          _vm.watchedSettings.autoHeight
                                      },
                                      formatter: _vm.formatter,
                                      hover: _vm.watchedSettings.gridHover,
                                      columns: _vm.gridColumns,
                                      rows: stack.gridData,
                                      labels: _vm.gridLabels,
                                      caption: stack.caption
                                    },
                                    on: {
                                      "hover-menu-click": _vm.clickHoverMenu,
                                      "row-double-click": _vm.doubleClick,
                                      "row-click": _vm.clickRow
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.watchedSettings.viewType !== _vm.VIEW_TYPES.STACK
                    ? _c("markets-ui-grid", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          groupKey: _vm.groupKey,
                          formatter: _vm.formatter,
                          hover: _vm.watchedSettings.gridHover,
                          option: _vm.gridOption,
                          columns: _vm.gridColumns,
                          rows: _vm.dataModel.data || [],
                          labels: _vm.gridLabels,
                          caption: _vm.initedLabels.glaTitle
                        },
                        on: {
                          "hover-menu-click": _vm.clickHoverMenu,
                          "row-double-click": _vm.doubleClick
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }