<template>
    <markets-ui-container
        :class="classes"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <markets-ui-settings
            ref="glaUiSettings"
            slot="settings"
            :title="initedLabels.settingTitle"
            :skin="watchedSettings.skin"
            :showBack="!!settingsPopoverNavSelected"
            :triggered-by="settingsPopoverTriggeredId"
            :visible="settingsPopoverVisible"
            @hide="hideSettingsPopover"
            @back="changeSettingsNav"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            >
            </markets-ui-settings-navigation>
            <markets-ui-list
                size="small"
                v-if="!!settingsPopoverNavSelected"
                variation="selection"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNavSelectedList"
                @change="changeSettingsNavSelected"
                slot="content"
            >
            </markets-ui-list>
        </markets-ui-settings>
        <div slot="content" :class="namespace('body')" v-if="!errorMessage">
            <div :class="namespace('navigation')">
                <markets-ui-menus
                    v-if="showWebLayout"
                    :skin="watchedSettings.skin"
                    :data-model="viewMenuDataModel"
                    collapse-mode="collapse"
                    :label="initedLabels.view"
                    @change="changeViewType"
                >
                </markets-ui-menus>
                <markets-ui-menus
                    v-if="showWebLayout"
                    :skin="watchedSettings.skin"
                    :data-model="exchangeMenuDataModel"
                    :collapse-mode="
                        watchedSettings.viewType === VIEW_TYPES.TAB
                            ? 'collapse'
                            : 'auto'
                    "
                    @change="changeSymbol"
                >
                </markets-ui-menus>
                <markets-ui-menus
                    v-if="watchedSettings.viewType === VIEW_TYPES.TAB"
                    :skin="watchedSettings.skin"
                    :dataModel="menuDataModel"
                    @change="changeType"
                >
                </markets-ui-menus>
            </div>
            <div
                :class="[
                    namespace('grid-container'),
                    watchedSettings.viewType === VIEW_TYPES.TAB || showWebLayout
                        ? ''
                        : namespace('grid-container__no-menu')
                ]"
            >
                <section
                    :class="stackClasses"
                    v-if="
                        watchedSettings.viewType === VIEW_TYPES.STACK &&
                            !showLoading
                    "
                >
                    <div
                        :class="namespace('stack')"
                        v-for="(stack, index) in stackDataModel"
                        :key="index"
                    >
                        <StackQuote
                            :skin="watchedSettings.skin"
                            :labels="initedLabels"
                            :noData="stack.noData"
                            :dataModel="stack.quoteData"
                            :symbolClickable="watchedSettings.symbolClickable"
                            :showStackTitle="watchedSettings.showStackTitle"
                            @symbol-click="symbolClick"
                        ></StackQuote>
                        <markets-ui-grid
                            v-if="!!stack.gridData.length"
                            :skin="skin"
                            :option="{
                                autoHeight: watchedSettings.autoHeight
                            }"
                            :formatter="formatter"
                            :hover="watchedSettings.gridHover"
                            :columns="gridColumns"
                            :rows="stack.gridData"
                            :labels="gridLabels"
                            :caption="stack.caption"
                            @hover-menu-click="clickHoverMenu"
                            @row-double-click="doubleClick"
                            @row-click="clickRow"
                        >
                        </markets-ui-grid>
                    </div>
                </section>
                <markets-ui-grid
                    v-if="watchedSettings.viewType !== VIEW_TYPES.STACK"
                    :skin="watchedSettings.skin"
                    :groupKey="groupKey"
                    :formatter="formatter"
                    :hover="watchedSettings.gridHover"
                    :option="gridOption"
                    :columns="gridColumns"
                    :rows="dataModel.data || []"
                    :labels="gridLabels"
                    :caption="initedLabels.glaTitle"
                    @hover-menu-click="clickHoverMenu"
                    @row-double-click="doubleClick"
                >
                </markets-ui-grid>
            </div>
        </div>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { dataPoints, stackDataPoints } from './metadata/data-point';
import StackQuote from './components/stack-quote';
import { VIEW_TYPES } from './common';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-gla-ui',
    mixins: [mixins.componentUI],
    components: { StackQuote },
    props: {
        dataModel: {
            type: Object,
            default() {
                return {
                    symbolList: [],
                    data: null
                };
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            watchedSettings: {},
            settingsPopoverVisible: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverNavSelected: undefined,
            width: null
        };
    },
    watch: {
        'watchedSettings.viewType': function handler(value) {
            if (value) {
                // Tracking: number of usage per exchange.
                this.trackEvent({
                    name: 'view-type-change',
                    value
                });
            }
        },
        'watchedSettings.symbol': function handler(value) {
            if (value) {
                // Tracking: number of usage on each view type (Tab/List).
                this.trackEvent({
                    name: 'exchange-change',
                    value: this.initedLabels[value]
                });
            }
        }
    },
    computed: {
        classes() {
            return [this.namespace()];
        },
        stackClasses() {
            const cls = [this.namespace('stack-container')];
            if (this.width > this.watchedSettings.breakpoint) {
                cls.push(this.namespace('stack__large'));
            } else {
                cls.push(this.namespace('stack__small'));
            }
            return cls;
        },
        stackDataModel() {
            const dataModels = [];
            if (this.dataModel.data) {
                this.watchedSettings.typeList.forEach(type => {
                    const stackData = {};
                    stackData.gridData = this.dataModel.data.filter(d => {
                        return d.type === type;
                    });
                    if (
                        this.dataModel.data.length &&
                        !stackData.gridData.length
                    ) {
                        stackData.noData = true;
                        stackData.quoteData = {
                            group: this.initedLabels[type]
                        };
                    } else {
                        stackData.noData = false;
                    }
                    if (stackData.gridData.length) {
                        stackData.quoteData = stackData.gridData.shift();
                    }
                    stackData.caption = this.initedLabels[type];

                    dataModels.push(stackData);
                });
            }
            return dataModels;
        },
        menuDataModel() {
            return this.watchedSettings.typeList.map(id => {
                return {
                    id,
                    name: this.initedLabels[id],
                    selected: this.watchedSettings.type === id
                };
            });
        },
        gridLabels() {
            const labels = {};
            [
                'previousPage',
                'previousColumn',
                'nextPage',
                'nextColumn'
            ].forEach(key => {
                labels[key] = this.initedLabels[key];
            });
            return labels;
        },
        gridOption() {
            const option = {};
            [
                'scrollbarSize',
                'focusable',
                'rowHeight',
                'autoHeight',
                'frozenColumn',
                'layout',
                'stickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[key] = this.watchedSettings[key];
                }
            });
            return option;
        },
        gridColumns() {
            let dps;
            if (this.watchedSettings.viewType === VIEW_TYPES.STACK) {
                dps = stackDataPoints;
            } else {
                dps = dataPoints;
            }
            return dps.map(dp => {
                if (dp !== performance) {
                    utils.extend(true, dp, {
                        name: this.initedLabels[dp.id],
                        sortable: false
                    });
                }
                if (dp.id === 'symbolName') {
                    dp.formatter = (value, d) => {
                        return this.generateSymbolNameHtml(d.symbol, d.name);
                    };
                }
                return dp;
            });
        },
        groupKey() {
            return this.watchedSettings.viewType === VIEW_TYPES.TAB
                ? ''
                : 'group';
        },
        settingsPopoverNav() {
            if (this.dataModel.symbolList.length === 0) {
                return [];
            }
            let selectedSymbol = utils.find(
                this.dataModel.symbolList,
                item => item.ticker === this.watchedSettings.symbol
            );
            if (!selectedSymbol) {
                selectedSymbol = this.dataModel.symbolList[0];
            }

            return [
                {
                    id: 'exchange',
                    name:
                        this.initedLabels[selectedSymbol.ticker] ||
                        selectedSymbol.name,
                    title: this.initedLabels.exchange
                },
                {
                    id: 'viewType',
                    name: this.initedLabels[this.watchedSettings.viewType],
                    title: this.initedLabels.viewType
                }
            ];
        },
        viewMenuDataModel() {
            let viewTypes;
            if (this.watchedSettings.viewTypeList) {
                viewTypes = this.watchedSettings.viewTypeList;
            } else {
                viewTypes = [VIEW_TYPES.TAB, VIEW_TYPES.LIST];
                if (this.showWebLayout) {
                    viewTypes.unshift(VIEW_TYPES.STACK);
                }
            }
            return viewTypes.map(viewType => {
                return {
                    id: viewType,
                    name: this.initedLabels[viewType],
                    selected: viewType === this.watchedSettings.viewType
                };
            });
        },
        exchangeMenuDataModel() {
            const data = this.dataModel.symbolList.map(item => {
                const { ticker, name } = item;
                return {
                    id: ticker,
                    name: this.initedLabels[ticker] || name,
                    selected: ticker === this.watchedSettings.symbol
                };
            });
            if (data.length > 0 && data.filter(d => d.selected).length === 0) {
                data[0].selected = true;
            }
            return data;
        },
        settingsPopoverNavSelectedList() {
            if (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'exchange'
            ) {
                return this.exchangeMenuDataModel;
            } else {
                return this.viewMenuDataModel;
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('gla');
        this.VIEW_TYPES = VIEW_TYPES;
        if (!this.watchedSettings.viewType) {
            this.watchedSettings.viewType = this.showWebLayout
                ? VIEW_TYPES.STACK
                : VIEW_TYPES.TAB;
        }
        this.triggerTypeChange();
    },

    methods: {
        _resizeObserverCallback({ entry }) {
            this.width = entry.contentRect.width;
        },
        toggleSettings(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggeredId = utils.computeTriggerId(e.target);
        },
        changeType(item) {
            if (item) {
                this.watchedSettings.type = item.id;
            }
            this.triggerTypeChange();
            this.changeSettings(this._getStoreSettings());
        },
        triggerTypeChange() {
            this.$emit(
                'type-changed',
                this.watchedSettings.symbol,
                this.watchedSettings.viewType === VIEW_TYPES.TAB
                    ? this.watchedSettings.type
                    : this.watchedSettings.typeList.join(',')
            );
        },
        clickHoverMenu(arg) {
            this.$emit('hover-menu-click', arg);
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.$emit('settings-popover-hide');
        },
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.$refs.glaUiSettings.enableTrapFocus();
        },
        changeSymbol(item) {
            this.watchedSettings.symbol = item.id;
            this.$emit('symbol-changed', this.watchedSettings.symbol);
            this.changeSettings(this._getStoreSettings());
        },
        changeViewType(item) {
            this.watchedSettings.viewType = item.id;
            this.changeType();
        },
        changeSettingsNavSelected(item) {
            if (this.settingsPopoverNavSelected) {
                if (this.settingsPopoverNavSelected.id === 'exchange') {
                    this.changeSymbol(item);
                } else if (this.settingsPopoverNavSelected.id === 'viewType') {
                    this.changeViewType(item);
                }
            }
            this.settingsPopoverNavSelected = null;
            this.hideSettingsPopover();
        },
        _getStoreSettings() {
            return {
                symbol: this.watchedSettings.symbol,
                type: this.watchedSettings.type,
                viewType: this.watchedSettings.viewType
            };
        },
        doubleClick(item) {
            this.$emit('row-double-click', item);
        },
        symbolClick(item) {
            this.$emit('symbol-click', item);
        },
        clickRow({ row, e }) {
            e.preventDefault();
            if (e.target.classList.contains(this.namespace('link'))) {
                this.$emit('symbol-click', row);
            }
        },
        generateSymbolNameHtml(symbol, name) {
            let symbolHtml, nameHtml;
            if (this.watchedSettings.symbolClickable) {
                symbolHtml = this.getMdsLinkHtml(symbol);
                nameHtml = this.getMdsLinkHtml(name);
            } else {
                symbolHtml = symbol;
                nameHtml = name;
            }
            return `<div class=${this.namespace(
                'grid-symbol'
            )}>${symbolHtml}</div><div class='${this.namespace(
                'grid-name'
            )}' title='${name}'>${nameHtml}</div>`;
        },
        getMdsLinkHtml(text) {
            return `<a href='javascript:void(0)' class='${this.namespace(
                'link'
            )}'>${text}</a>`;
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/link';
$namespace: 'mwc-markets-gla';
.#{$namespace} {
    &-link {
        @include mds-link();
    }
    &-navigation {
        display: flex;
    }
    &-header__bold {
        display: inline-flex;
        align-items: center;
        span {
            margin-left: $mds-space-half-x;
        }
    }
    &-body {
        .markets-ui-menus {
            box-sizing: border-box;
            height: 45px;
            padding: $mds-space-2-x $mds-space-half-x 0 $mds-space-half-x;
        }
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-body {
            height: 100%;
        }
        .#{$namespace}-grid-container {
            height: calc(100% - 45px);
            overflow: auto;
            &__no-menu {
                height: 100%;
            }
        }
    }

    &-stack-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 4px;
        .markets-ui-table__text-hover {
            overflow: hidden;
        }
        .markets-ui-table__cell {
            padding: 12px 0;
        }
        .markets-ui-table__cell-upDownWithIconAndValue {
            .markets-ui-icon svg {
                margin-top: 3px;
            }
        }
        .markets-ui-table__header-cell:first-child,
        .markets-ui-table__cell--left.markets-ui-table__cell:first-child {
            padding-left: $mds-space-1-and-a-half-x;
        }
        .markets-ui-table__header-cell:last-child,
        .markets-ui-table__cell--right.markets-ui-table__cell:last-child {
            padding-right: $mds-space-1-and-a-half-x;
        }
        .markets-ui-table__body {
            border: solid 1px $mds-color-neutral-80;
            border-left-width: 2px;
        }
        .markets-ui-table__header-cell-inner {
            margin-bottom: 0;
        }
        .markets-ui-table__cell-inner > div,
        .markets-ui-table__cell-inner a,
        .markets-ui-table__header-cell-inner {
            white-space: nowrap;
        }
        .markets-ui-table {
            padding: 4px 0;
            width: calc(100% - 1px);
        }
        .mwc-markets-gla-grid-name {
            margin-top: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    &-stack-quote {
        width: calc(100% - 2px);
    }
    &-grid-name {
        margin-top: $mds-space-half-x;
    }
    &-stack__large &-stack {
        width: calc((100% - 48px) / 3);
    }
    &-stack__small &-stack {
        width: 100%;
    }
    .markets-ui-table__cell-symbolName {
        max-width: 140px;
    }
}
</style>
