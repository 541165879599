<template>
    <section :class="classes">
        <div :class="namespace('title')" v-if="showStackTitle">
            {{ dataModel.group }}
        </div>
        <div :class="namespace('content')">
            <section :class="namespace('symbol-name')" v-if="!noData">
                <div :class="namespace('symbol')">
                    {{ dataModel.symbol }}
                </div>
                <div :class="namespace('name')">
                    <a
                        href="javascript:void(0)"
                        :class="[namespace('link')]"
                        v-if="symbolClickable"
                        @click.prevent="symbolClick($event)"
                        >{{ dataModel.name }}</a
                    >
                    <span v-else>{{ dataModel.name }}</span>
                </div>
            </section>
            <section :class="namespace('price-change')" v-if="!noData">
                <div :class="namespace('price')">
                    <div
                        :class="[namespace('price-label'), namespace('label')]"
                    >
                        {{ labels.lastPriceLabel }}
                    </div>
                    <div
                        :class="[namespace('price-value'), namespace('value')]"
                    >
                        {{ formatDataModel.lastPrice }}
                    </div>
                </div>
                <div :class="namespace('change')">
                    <div
                        :class="[namespace('change-label'), namespace('label')]"
                    >
                        {{ labels.change }}
                    </div>
                    <div
                        :class="[
                            namespace('change-value'),
                            namespace('value'),
                            namespace(upDown)
                        ]"
                    >
                        <markets-ui-icon
                            :name="`ip-performance-arrow-${upDown}`"
                            :title="labels[upDown]"
                            :performance="upDown"
                        ></markets-ui-icon>
                        <span :class="namespace('chg-text')">{{
                            changeText
                        }}</span>
                    </div>
                </div>
            </section>
            <section :class="namespace('no-data')" v-if="noData">
                {{ labels.dataNotAvailable }}
            </section>
        </div>
    </section>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import { dataPoints } from '../metadata/data-point';
const { utils } = mwcMarketsCore;
const PERFORMANCE = {
    UP: 'up',
    DOWN: 'down',
    NEUTRAL: 'neutral'
};
export default {
    name: 'mwc-markets-gla-stack-quote',
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        skin: {
            type: String,
            default: ''
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        },
        symbolClickable: {
            type: Boolean,
            default: false
        },
        noData: {
            type: Boolean,
            default: false
        },
        showStackTitle: {
            type: Boolean,
            default: true
        }
    },
    created() {
        this.namespace = utils.namespace('gla-stack-quote');
    },
    computed: {
        classes() {
            return [this.namespace()];
        },
        upDown() {
            if (this.dataModel.chg > 0) {
                return PERFORMANCE.UP;
            } else if (this.dataModel.chg === 0) {
                return PERFORMANCE.NEUTRAL;
            } else {
                return PERFORMANCE.DOWN;
            }
        },
        formatDataModel() {
            const formatData = {};
            let dpDataType;
            ['lastPrice', 'chg', 'chg%'].forEach(dp => {
                if (dp === 'chg' || dp === 'chg%') {
                    dpDataType = 'number';
                } else if (dp === 'lastPrice') {
                    dpDataType = utils.find(dataPoints, element => {
                        return element.id === 'lastPrice';
                    }).dataType;
                }
                formatData[dp] = utils.getFormatValue({
                    listedCurrency: this.dataModel.listedCurrency,
                    value: this.dataModel[dp],
                    dataType: dpDataType
                });
                if (dp !== 'lastPrice' && this.dataModel[dp] > 0) {
                    formatData[dp] = `+${formatData[dp]}`;
                }
                if (dp === 'chg%') {
                    formatData[dp] += '%';
                }
            });
            return formatData;
        },
        changeText() {
            return `${this.formatDataModel.chg}(${this.formatDataModel['chg%']})`;
        },
        isNeutral() {
            return this.upDown === PERFORMANCE.NEUTRAL;
        }
    },
    methods: {
        symbolClick(e) {
            this.$emit('symbol-click', this.dataModel);
        }
    }
};
</script>
<style lang="scss">
@import '@core/src/mds/constants.scss';
$namespace: 'mwc-markets-gla-stack-quote';
.#{$namespace} {
    font-size: $mds-typography-font-size-m;
    font-weight: $mds-typography-font-weight-regular;
    line-height: $mds-typography-line-height-m;

    &-title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: $mds-typography-font-weight-regular;
        padding: $mds-space-1-x 0 $mds-space-1-and-a-half-x 0;
    }
    &-content {
        background-color: $mds-background-color-light-gray;
        padding: $mds-space-1-and-a-half-x;
        margin-bottom: $mds-space-1-x;
    }

    &-symbol {
        font-size: 20px;
        line-height: 1.2;
        white-space: nowrap;
    }
    &-name {
        font-size: 14px;
        white-space: nowrap;
    }
    &-price-change {
        display: flex;
        padding-top: 5px;
    }

    &-change {
        padding: 0 $mds-space-1-and-a-half-x;
    }
    &-label {
        font-size: $mds-typography-font-size-s;
        white-space: nowrap;
        color: $mds-text-color-secondary;
        font-weight: $mds-typography-font-weight-bold;
    }
    &-change-value {
        display: flex;
        white-space: nowrap;
        align-items: center;
    }
    &-chg-text {
        margin-top: 2px;
        white-space: nowrap;
        font-weight: $mds-typography-font-weight-bold;
    }
    &-up {
        fill: $mds-text-color-performance-positive;
        color: $mds-text-color-performance-positive;
    }
    &-down {
        fill: $mds-text-color-performance-negative;
        color: $mds-text-color-performance-negative;
    }
}

.markets-ui-container__dark-gray {
    .#{$namespace} {
        &-title {
            color: $mds-text-color-primary-on-dark;
        }
        &-content {
            background-color: $mds-color-neutral-20;
            color: $mds-text-color-primary-on-dark;
        }
        &-label {
            color: $mds-text-color-secondary-on-dark;
        }
        &-link {
            @include mds-link();
        }
    }
}
</style>
