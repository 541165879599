import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
const DEFAULT_TYPE_LIST = ['D2158', 'D2159', 'D2160'];
const ALL_TYPE_LIST = DEFAULT_TYPE_LIST.concat(['D2157', 'D2156']);
export const VIEW_TYPES = {
    TAB: 'tab',
    LIST: 'list',
    STACK: 'stack'
};

export const TYPE_LIST_RANGE = {
    DEFAULT: 'default',
    ALL: 'all'
};
export function getTypeListByRange(range) {
    if (range === TYPE_LIST_RANGE.ALL) {
        return ALL_TYPE_LIST;
    } else {
        return DEFAULT_TYPE_LIST;
    }
}

export const LIST_TYPE = {
    TYPE_LIST: 'typeList',
    SYMBOL_LIST: 'symbolList'
};

/**
 * Verify type or symbol list.
 * @param listType {TYPE_LIST_RANGE}- list type.
 * @param list - the type or symbol list.
 * @returns {any[]}
 */
export function verifyTypeList(list) {
    if (!list) {
        return null;
    }
    const defaultList = getTypeListByRange(TYPE_LIST_RANGE.DEFAULT);
    const allList = getTypeListByRange(TYPE_LIST_RANGE.ALL);
    const result = list.filter(item => {
        return utils.inArray(allList, item) !== -1;
    });
    // We need remove duplication and if the result is empty use default list.
    return Array.from(new Set(result.length === 0 ? defaultList : result));
}

export function getDefaultTypeList() {
    return getTypeListByRange(TYPE_LIST_RANGE.DEFAULT);
}
