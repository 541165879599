var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.classes }, [
    _vm.showStackTitle
      ? _c("div", { class: _vm.namespace("title") }, [
          _vm._v("\n        " + _vm._s(_vm.dataModel.group) + "\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.namespace("content") }, [
      !_vm.noData
        ? _c("section", { class: _vm.namespace("symbol-name") }, [
            _c("div", { class: _vm.namespace("symbol") }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.dataModel.symbol) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.namespace("name") }, [
              _vm.symbolClickable
                ? _c(
                    "a",
                    {
                      class: [_vm.namespace("link")],
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.symbolClick($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.dataModel.name))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.dataModel.name))])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.noData
        ? _c("section", { class: _vm.namespace("price-change") }, [
            _c("div", { class: _vm.namespace("price") }, [
              _c(
                "div",
                {
                  class: [_vm.namespace("price-label"), _vm.namespace("label")]
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.labels.lastPriceLabel) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [_vm.namespace("price-value"), _vm.namespace("value")]
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.formatDataModel.lastPrice) +
                      "\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.namespace("change") }, [
              _c(
                "div",
                {
                  class: [_vm.namespace("change-label"), _vm.namespace("label")]
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.labels.change) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.namespace("change-value"),
                    _vm.namespace("value"),
                    _vm.namespace(_vm.upDown)
                  ]
                },
                [
                  _c("markets-ui-icon", {
                    attrs: {
                      name: "ip-performance-arrow-" + _vm.upDown,
                      title: _vm.labels[_vm.upDown],
                      performance: _vm.upDown
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { class: _vm.namespace("chg-text") }, [
                    _vm._v(_vm._s(_vm.changeText))
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.noData
        ? _c("section", { class: _vm.namespace("no-data") }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.labels.dataNotAvailable) +
                "\n        "
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }