var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GLAUI", {
    ref: "UI",
    attrs: {
      showLoading: _vm.showLoading,
      dataModel: _vm.dataModel,
      skin: _vm.skin,
      errorCode: _vm.error.code,
      settings: _vm.settings,
      labels: _vm.initedLabels,
      formatter: _vm.formatter
    },
    on: {
      track: _vm.trackEvent,
      "type-changed": _vm.changeType,
      "hover-menu-click": _vm.clickHoverMenu,
      "row-double-click": _vm.doubleClick,
      "symbol-click": _vm.clickSymbol,
      "symbol-changed": _vm.changeSymbol,
      "settings-changed": _vm.changeSettings,
      "settings-popover-hide": _vm.hideSettingsPopover
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }