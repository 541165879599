<template>
    <GLAUI
        ref="UI"
        :showLoading="showLoading"
        :dataModel="dataModel"
        :skin="skin"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        @track="trackEvent"
        @type-changed="changeType"
        @hover-menu-click="clickHoverMenu"
        @row-double-click="doubleClick"
        @symbol-click="clickSymbol"
        @symbol-changed="changeSymbol"
        @settings-changed="changeSettings"
        @settings-popover-hide="hideSettingsPopover"
    >
    </GLAUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { getDefaultTypeList, verifyTypeList } from './common';
import GLAUI from './gla-ui';

const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-gla',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    layout: '',
                    typeList: getDefaultTypeList(),
                    viewTypeList: '', //config the view type options in settings. Optional values are tab,list,stack
                    autoHeight: true,
                    symbol: '126.1.MSTATS_ALL_MS',
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    languageId: 'en-US',
                    sortable: false,
                    focusable: false,
                    updateLastAccessTime: true,
                    frozenColumn: 0,
                    type: 'D2158', // three data type gainers D2158 losers D2159 most actives D2160
                    skin: '',
                    viewType: null, //list,tab,stack, by default: webLayout will use stack, otherwise use tab
                    breakpoint: 800,
                    showStackTitle: true,
                    symbolClickable: false, // control if the symbol-click event can be triggered
                    stickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 2,
                            minWidth: 0,
                            maxWidth: 500
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 4,
                            minWidth: 500,
                            maxWidth: 640
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 6,
                            minWidth: 640,
                            maxWidth: 960
                        }
                    ]
                },
                labels: labels,
                format: {
                    integer: {
                        dataType: 'number',
                        useGrouping: true
                    },
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    time: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    }
                },
                intlNamespace: 'mwc-markets-gla'
            },
            dataModel: {
                symbolList: [],
                data: null
            },
            showLoading: false
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    created() {
        this.invalidInitSymbol = null;
        this.typeLabels = {};
        this.pollingTimeout = null;
        this.initialized = false;
        this.targetVisibility = false;
        this._setValidType();
        //  Generate type label must after _setValidTypeAndSymbolList, if not  you can not get correct type labels.
        this.settings.typeList.map(type => {
            this.typeLabels[type] = this.initedLabels[type];
        });
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        this._stopPolling();
    },
    methods: {
        async initialize() {
            this.initialized = true;
            const tickers = await this.getGLATickers();
            if (tickers && tickers.length) {
                const idx = utils.inArray(
                    tickers,
                    t => t.ticker === this.settings.symbol
                );
                if (idx < 0) {
                    this.invalidInitSymbol = this.settings.symbol;
                    this.settings.symbol = tickers[0].ticker;
                }
                this.dataModel.symbolList = tickers;
                this.getGLAData();
            } else {
                this._setError(this.ERROR_TYPES.No_PERMISSION_INVESTMENT);
            }
        },
        _setValidType() {
            this.settings.typeList = verifyTypeList(this.settings.typeList);
            if (
                (this.settings.viewType === 'tab' &&
                    this.settings.typeList.includes(this.settings.type)) ||
                this.settings.type.search(',') !== -1
            ) {
                return;
            }
            this.settings.type = this.settings.typeList[0];
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._startPolling();
                }
            } else {
                if (!this.targetVisibility) {
                    this._stopPolling();
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        getGLATickers() {
            if (this.mktdata) {
                this.showLoading = true;
                return this.mktdata.statisticsTickers().catch(e => {
                    this._setError(this.ERROR_TYPES.SERVICE);
                });
            }
        },
        getGLAData(isPolling) {
            if (this.settings.symbol && this.settings.type && this.mktdata) {
                if (!isPolling) {
                    this.showLoading = true;
                }
                this.mktdata
                    .statistics({
                        instruments: [this.settings.symbol],
                        statFields: this.settings.type,
                        updateLastAccessTime: this.settings
                            .updateLastAccessTime,
                        quoteFields:
                            'D2,D3,D4,D17,D18,D19,D784,D770,D2124,H8,H10,H15,H16,S9,S12,S658,S3377'
                    })
                    .then(data => {
                        if (!isPolling) {
                            this.showLoading = false;
                        }
                        if (data.length === 0) {
                            this._setError(this.ERROR_TYPES.NODATA);
                        } else {
                            this._fillDataModel(data);
                            this.renderCompleted = true;
                            this._startPolling();
                        }
                    })
                    .catch(e => {
                        if (!isPolling) {
                            this._setError(this.ERROR_TYPES.SERVICE);
                        }
                    });
            }
        },
        _startPolling() {
            this._stopPolling();
            this.pollingTimeout = setTimeout(() => {
                this.getGLAData(true);
            }, 60000);
        },
        _stopPolling() {
            if (this.pollingTimeout !== null) {
                clearTimeout(this.pollingTimeout);
                this.pollingTimeout = null;
            }
        },
        _fillDataModel(data) {
            const rows = [];
            data.forEach(d => {
                if (d.instrument === this.settings.symbol) {
                    d.quotes.forEach(q => {
                        q.value.forEach(f => {
                            rows.push(
                                utils.extend(
                                    {
                                        //  Use group + instrument to fix grid focus issue.
                                        //  We must ensure the id is unique.
                                        //  You can find the details in https://msjira.morningstar.com/browse/QS-15058 .
                                        id: `${this.typeLabels[q.name] ||
                                            q.name}:${f.instrument}`,
                                        group:
                                            this.typeLabels[q.name] || q.name,
                                        type: q.name
                                    },
                                    this._getFields(f.quotes)
                                )
                            );
                        });
                    });
                }
            });
            if (
                this.dataModel.data === null ||
                this.dataModel.data.length !== rows.length ||
                JSON.stringify(this.dataModel.data) !== JSON.stringify(rows)
            ) {
                this.dataModel.data = rows;
            }
        },
        _getFields(quotes) {
            const map = {};
            quotes.forEach(q => {
                map[q.name] = q.value;
            });
            if (!map['MSStandardName'] && map['name']) {
                map['name'] = map['name'].replace(/\b(\w)(\w*)/g, function(
                    $0,
                    $1,
                    $2
                ) {
                    return $1.toUpperCase() + $2.toLowerCase();
                });
            }
            map['MSStandardName'] = map['MSStandardName'] || map['name'];
            return map;
        },
        clickHoverMenu({ item, row }) {
            const id = row.id.split(':')[1];
            this.exposeSecurity(id)
                .then(params => {
                    this.$emit('hover-menu-click', item, params);
                })
                .catch(() => {
                    this.$emit('hover-menu-click', item, id);
                });
        },
        doubleClick({ row }) {
            const id = row.id.split(':')[1];
            this.exposeSecurity(id)
                .then(params => {
                    this.$emit('row-double-click', params);
                })
                .catch(() => {
                    this.$emit('row-double-click', id);
                });
        },
        clickSymbol(item) {
            const id = item.id.split(':')[1];
            this.exposeSecurity(id)
                .then(params => {
                    this.$emit('symbol-click', params);
                })
                .catch(() => {
                    this.$emit('symbol-click', id);
                });
        },
        changeType(symbol, type) {
            this._clearError();
            this.settings.type = type;
            this.dataModel.data = null;
            if (this.initialized) {
                // settings symbol changed but UI watchedSettings symbol is no changed.
                // need to find a better solution to make settings is responsive
                if (symbol !== this.invalidInitSymbol) {
                    this.settings.symbol = symbol;
                }
                this.getGLAData();
            }
        },
        changeSymbol(value) {
            this._clearError();
            if (this.initialized) {
                this.settings.symbol = value;
                this.getGLAData();
            }
        },
        hideSettingsPopover() {
            this.$emit('settings-popover-hide');
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        },
        _clearError() {
            this.error = {};
            this.showLoading = false;
        }
    },
    components: {
        GLAUI
    }
};
</script>
