export const dataPoints = [
    {
        id: 'MSStandardName',
        align: 'left',
        width: 250,
        dataType: 'tree',
        titleable: true
    },
    {
        id: 'symbol',
        align: 'left',
        dataType: 'string'
    },
    {
        id: 'lastPrice',
        align: 'right',
        dataType: 'withCurrency',
        flash: 'color'
    },
    {
        id: 'chg',
        align: 'right',
        dataType: 'upDown',
        flash: 'color'
    },
    {
        id: 'chg%',
        align: 'right',
        dataType: 'upDownWithPercent',
        flash: 'color'
    },
    {
        id: 'openPrice',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'highPrice',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'lowPrice',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'volume',
        align: 'right',
        width: 100,
        dataType: 'int'
    },
    {
        id: 'tradeDate',
        align: 'right',
        width: 100,
        dataType: 'date'
    },
    {
        id: 'tradeTime',
        align: 'right',
        width: 100,
        dataType: 'time'
    }
];
export const stackDataPoints = [
    {
        id: 'symbolName',
        align: 'left',
        formatter: 'symbolName',
        dataType: 'tree',
        titleable: true
    },
    {
        id: 'lastPrice',
        align: 'right',
        dataType: 'withCurrency',
        flash: 'color'
    },
    {
        id: 'chg%',
        align: 'right',
        dataType: 'upDownWithIconAndValue',
        flash: 'color'
    }
];
